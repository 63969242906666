import $ from "jquery";

$(document).on("turbolinks:load", () => {
  if ($("#user_form").length) {
    const toggleFields = () => {
      const roleValue = $("#user_role").val();
      const providerSelect = $("#user_provider_id");
      const wsTypeSelect = $("#user_ws_type");

      if (roleValue === "provider") {
        // Mostrar y habilitar el campo de prestador
        $("#provider_fields").show();
        providerSelect.prop("disabled", false);
        $("#user_phone_country_code").prop("required", true).prop("disabled", false);
        $("#user_phone").prop("required", true).prop("disabled", false);
      } else {
        // Ocultar y deshabilitar el campo de prestador
        providerSelect.prop("disabled", true);
        $("#provider_fields").hide();
        $("#user_phone_country_code").prop("required", false).prop("disabled", true);
        $("#user_phone").prop("required", false).prop("disabled", true);
      }

      if (roleValue === "api_consumer") {
        $("#ws_type_field").show();
        wsTypeSelect.prop("disabled", false);
      } else {
        // Ocultar y deshabilitar el campo de ws_type, y establecer "No utiliza API"
        $("#ws_type_field").hide();
        wsTypeSelect.val('none'); // Se asegura que el valor sea "No utiliza API"
      }
    };

    // Ejecutar la función al cargar la página y al cambiar el rol
    toggleFields();
    $("#user_role").on("change", () => {
      toggleFields();
    });

    // Validación de contraseñas para habilitar/deshabilitar el botón de submit
    $('#pass, #confirmPass').on('keyup', () => {
      $('input[name="commit"]').prop('disabled', true);
      const pass = $('#pass').val();
      const confirmPass = $('#confirmPass').val();
      if (pass === confirmPass) {
        $('input[name="commit"]').prop('disabled', false);
        $('#passErrorMessage').text('');
      } else {
        $('input[name="commit"]').prop('disabled', true);
        $('#passErrorMessage').text('Error: contraseñas son distintas');
      }
    });

    // Agregar el filtro para prevenir la inserción de emojis
    const fields = document.querySelectorAll('input[type="text"], textarea, input[type="password"], input[type="email"]');

    fields.forEach(function(field) {
      field.addEventListener('beforeinput', function(event) {
        // Verifica si el valor ingresado es un emoji
        const emojiRegex = /[\p{Emoji_Presentation}\u200d\u20e3\ufe0f]/gu;
        if (emojiRegex.test(event.data)) {
          event.preventDefault(); // Previene la inserción del emoji
        }
      });
    });
  }
});
