document.addEventListener("turbolinks:load", function() {
    let downloadButton = $('#downloadVoucherCajaBtn');
    if (downloadButton.length) {
        downloadButton.on('click', function(e) {
            e.preventDefault();
            const token = $(this).data('token'); // Usamos jQuery para ser consistentes
            const url = `/prestamos/caja_los_andes/voucher_credito?token=${token}`;
            const messageContainer = document.getElementById('messageContainer');

            fetch(url)
                .then(response => {
                    console.log(response);
                    if (response.ok && response.headers.get('Content-Type') === 'application/pdf') {
                        response.blob().then(blob => {
                            const downloadUrl = window.URL.createObjectURL(blob);
                            const a = document.createElement('a');
                            a.href = downloadUrl;
                            a.download = `voucher-${token}.pdf`;
                            document.body.appendChild(a);
                            a.click();
                            a.remove();
                            window.URL.revokeObjectURL(downloadUrl);
                        });
                    } else {
                        showMessage('El archivo aún no está listo, espera unos segundos.');
                    }
                })
                .catch(error => {
                    console.error('Error fetching the PDF:', error);
                    showMessage('Hubo un error al intentar descargar el archivo.');
                });

            function showMessage(message) {
                messageContainer.style.display = 'block';
                messageContainer.textContent = message;
                setTimeout(() => {
                    messageContainer.style.display = 'none';
                }, 2000); // Hace que la alerta desaparezca después de 2 segundos
            }
        });
    }
});
