import $ from "jquery";

const startFinancierShowToggles = () => {

  document.querySelectorAll('.auto-send-toggle').forEach(switchEl => {
    switchEl.addEventListener('change', (event) => {
      const form = event.target.closest("form")
      if (form) {
        form.submit();
      }
    });
  });
}

$(document).on('turbolinks:load', function() {

  startFinancierShowToggles()

  if ($('.admin-financier-form').length) {
    const mexicoFields = $('#mexico_fields');
    const colombiaFields = $('#colombia_fields');
  
    const toggleCountryFields = () => {
      const selectedCountryId = $('#financier_country_select').val();
  
      if (selectedCountryId === '142') { // ID para México
        mexicoFields.show();
        colombiaFields.hide();
        // Habilitar 'required' para los campos de México y deshabilitar para los de Colombia
        $('#mexico_fields input, #mexico_fields select').prop('required', true);
        $('#colombia_fields input, #colombia_fields select').prop('required', false);
      } else if (selectedCountryId === '50') { // ID para Colombia
        mexicoFields.hide();
        colombiaFields.show();
        // Habilitar 'required' para los campos de Colombia y deshabilitar para los de México
        $('#mexico_fields input, #mexico_fields select').prop('required', false);
        $('#colombia_fields input, #colombia_fields select').prop('required', true);
      } else {
        // Si ningún país está seleccionado, deshabilitar el 'required' en ambos grupos de campos
        mexicoFields.hide();
        colombiaFields.hide();
        $('#mexico_fields input, #mexico_fields select').prop('required', false);
        $('#colombia_fields input, #colombia_fields select').prop('required', false);
      }
    };
  
    // Ejecutar al cargar la página
    toggleCountryFields();
  
    // Escuchar el cambio en la selección del país
    $('#financier_country_select').on('change', function() {
      toggleCountryFields();
    });

    // Ocultar secciones inicialmente
    const insuranceSection = $('.financier-insurance-section');
    const creditSection = $('.financier-credit-section');

    const disableFields = (section) => {
      section.find('input, select, textarea').prop('disabled', true);
    };

    const enableFields = (section) => {
      section.find('input, select, textarea').prop('disabled', false);
    };

    // Inicialmente deshabilitar ambas secciones
    disableFields(insuranceSection);
    disableFields(creditSection);

    const toggleSections = (selectedType) => {
      if (selectedType === 'insurance') {
        insuranceSection.show();
        enableFields(insuranceSection);
        creditSection.hide();
        disableFields(creditSection);
      } else if (selectedType === 'credit') {
        creditSection.show();
        enableFields(creditSection);
        insuranceSection.hide();
        disableFields(insuranceSection);
      }
    };

    // Llamar a toggleSections al cambiar el tipo de financiamiento
    $('#financier_financing_type').on('change', function() {
      const selectedType = $(this).val();
      toggleSections(selectedType);
    });

    // Ejecutar la lógica al cargar la página para manejar la sección visible/invisible según el valor inicial
    const initialSelectedType = $('#financier_financing_type').val();
    toggleSections(initialSelectedType);

    // Validación de los campos de dinero
    const moneyFields = [
      '#financier_min_value_cents',
      '#financier_max_value_cents',
      '#financier_fixed_monthly_charge_cents',
      '#financier_variable_monthly_charge_cents',
      '#financier_evaluation_limit_cents'
    ];

    moneyFields.forEach(function(selector) {
      $(selector).on('input', function() {
        let value = $(this).val();

        // Permitir solo números y puntos
        value = value.replace(/[^0-9.]/g, '');

        // Limitar a un solo punto decimal
        const parts = value.split('.');
        if (parts.length > 2) {
          value = parts[0] + '.' + parts.slice(1).join('');
        }

        // Limitar a 999.999.999
        const numericValue = parseFloat(value);
        if (numericValue > 999999999) {
          value = '999999999';
        }

        // Asegurar que sea positivo
        if (numericValue < 0) {
          value = Math.abs(numericValue).toString();
        }

        $(this).val(value);
      });
    });

    // Función para obtener el símbolo de la moneda
    const getCurrencySymbol = (currency) => {
      switch (currency) {
        case 'CLF':
          return 'UF';
        case 'CLP':

        case 'USD':

        default:
          return currency || '-';
      }
    };

    // Escuchar cambios en el selector de moneda
    $('.currency-selector').on('change', function() {
      const selectedCurrency = $(this).val();
      const currencySymbol = getCurrencySymbol(selectedCurrency);

      // Actualizar todos los elementos con el target 'currency-symbol'
      $('[data-currency-target="currency-symbol"]').text(currencySymbol);
    });

    // Ejecutar la lógica al cargar la página para establecer el símbolo correcto
    const initialCurrency = $('.currency-selector').val();
    const initialSymbol = getCurrencySymbol(initialCurrency);
    $('[data-currency-target="currency-symbol"]').text(initialSymbol);
  }
});