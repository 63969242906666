import $ from "jquery";

const activateSelectFilters = () => {
  const selectFilters = $('.filter-select');
  const filterForm = selectFilters.closest("form")

  selectFilters.on("select2:select", () => {
    filterForm.submit()
  })

}

document.addEventListener("turbolinks:load", () => {
  if ($('.filter-select')) {
    activateSelectFilters()
  }
})