import $ from "jquery";

const activateSelectFilters = () => {

  $('.select-responsible').on('select2:select', function (e) {
    const form = $(this).closest('form');
    const formData = form.serialize();

    $.ajax({
      url: form.attr('action'),
      type: form.attr('method'),
      data: formData,
      dataType: 'json',
      success: function (response) {
      },
      error: function (xhr, status, error) {
        try {
          const errorResponse = JSON.parse(xhr.responseText);

          if (!errorResponse.success) {
            alert(errorResponse.error);
          }

        } catch (parseError) {
          console.error('Error al analizar la respuesta del servidor:', parseError);
        }
      }
    });
  });

}

document.addEventListener("turbolinks:load", () => {

  if ($('.select-responsible')) {
    activateSelectFilters()
  }

})