import $ from "jquery";

$( document ).on('turbolinks:load', function(){
  if ($('#edit_branch').length) {
    let $branch_billing = $('.branch-billing');
    let $finish_branches = $('#confirm_branches_form');
    let $accept_modal = $('#btn_confirm_complete_modal')
    let $completed_branches = $('#completed_branches');
    let $flag_modal = false;

    $finish_branches.on('submit', function(e){
      if(!$flag_modal && $completed_branches.val() === "false"){
        e.preventDefault();
      }
      $flag_modal = false;
    })

    $accept_modal.on('click', function(){
      $flag_modal = true;
      $finish_branches.submit();
    });

    const choseBilling = function($option){
      let $input = $branch_billing.find('.branch-billing__input');
      $input.val($option.data('billingId'));
      let $btn = $branch_billing.find('.branch-billing__btn');
      $btn.text($option.data('billingBusinessName'));
    };

    $branch_billing
      .on('click',".dropdown-item.add-billing", function(e){
        $(this).addClass('clicked');
        e.preventDefault();
      })
      .on('click', ".dropdown-item.billing-created", function(e){
        choseBilling($(this));
        e.preventDefault();
      });
    let created = function(html){
      $('.branch-billing__options').append($(html));
      $('.billing-modal__close-btn').trigger('click');
      choseBilling($('.dropdown-item.billing-created:last-child'));
    };

    let errorCreating = function(event){
      // TODO: mostrar un mensaje de error
      console.log("error", event)
    }
    let showError = function(errors){
      if(errors['business_id']){
        $('#billing_business_id').addClass('is-invalid');
      }
    }


    $("#create_billing_form").on("ajax:success", (e) => {
      let result = event.detail[0];
      const dm_provider_billing_id_select = $("#dm_provider_billing_id");
      if (dm_provider_billing_id_select) {
        dm_provider_billing_id_select.append(
          `<option value="${result.billing.id}" >${result.info}</option>`
        )
      }
    }).on("ajax:error", function (event) {
      console.log('error billing', event);
    });

    $('#create_billing_form').on("ajax:success", function(event) {
      let result = event.detail[0];
      let success = result.success;
      if(success){
        created(result.content);
      }else{
        showError(result.errors);
        errorCreating(event);
      }
    }).on("ajax:error", function(event) {
      errorCreating(event);
    });

    // linpia el error si el billing_business_id se edita
    $('#billing_business_id').on('input', function(e){
      $('#billing_business_id').removeClass('is-invalid');
    });

    /* const choseDmProvider = function($option) {
      $('#branch_dm_provider_id').val($option.data('dmProviderId'));
      $('#dropdownMenuButton-dm-provider').text($option.data('dmProviderInfo'));
    }; */

    $('#dm-provider-dropdown-menu').on('click', '.dropdown-item.dm-provider-created', function(e) {
      let $this = $(this),
          dmProviderId = $this.data('dm-provider-id'),
          dmProviderInfo = $this.data('dm-provider-info'),
          $dropdownButton = $('#dropdownMenuButton-dm-provider'),
          $dmProviderInput = $('#branch_dm_provider_id');
          
      $dropdownButton.html(dmProviderInfo);
      $dmProviderInput.val(dmProviderId);
      e.preventDefault();
    });

    $("#create_dm_provider_form").on("ajax:success", function(event) {
      let detail = event.detail[0];
      let success = detail.success;
      if (success) {
        let content = detail.content;
        let showInfo = detail.info;
        let dropdown = $('#dm-provider-dropdown-menu');
        let dropdownMenuBtn = $('#dropdownMenuButton-dm-provider');
        let dmProviderClicked = $(".dropdown-item.add-dm-provider.clicked");
        let modal = $('#dm-provider_modal');
        let closeBtn = modal.find('.close');

        dropdown.append(content);
        dropdownMenuBtn.html(showInfo);
        dmProviderClicked.removeClass('clicked');
        closeBtn.click();
      } else {
        event.preventDefault();
      }
    }).on("ajax:error", function(event) {
      event.preventDefault();
    });

    // ///////////
    // Edicion de titulo
    $('.edit-title').each(function(){
      const $container = $(this);
      // elementos de ver
      const $showText = $container.find('.edit-title__text');
      const $showBtnEdit = $container.find('.edit-title__btn-edit');
      // elementos de edicion
      const $editField = $container.find('.edit-title__field');
      const $editBtnOk = $container.find('.edit-title__btn-ok');
      const $editBtnCancel = $container.find('.edit-title__btn-cancel');

      // acciones
      const edit = function(ev){
        if (ev) {
          ev.preventDefault();
        }
        // respaldamos el valor actual
        $editField.data('current',$editField.val());
        $container.addClass('editing');
        $editField.trigger('focus');
      };
      const apply = function(ev) {
        if (ev) {
          ev.preventDefault();
        }
        // actualizamos el valor
        $showText.text( $editField.val() );
        $editField.data('current', $editField.val());
        $container.removeClass('editing');
        // extension: si hubiera algun campo externo adicional que cambiar
        $('.edit-title__external-text').text( $editField.val() );
      };
      const cancel = function(ev) {
        if (ev) {
          ev.preventDefault();
        }
        // restauramos el valor actual
        $editField.val( $editField.data('current') );
        $container.removeClass('editing');
      }

      // asociamos las acciones a los botones
      $showBtnEdit.on('click',edit);
      $editBtnOk.on('click',apply);
      $editBtnCancel.on('click',cancel);
      // por último, los eventos del campo:
      $editField
        .on('focus',function (){
          // cuando gana el foco, seleccionamos el texto
          $(this).select();
        })
        .on('blur',function (ev){
          // si se pierde el foco, cancelamos, pero con un pequeño delay en caso que haya sido por clic en ok
          window.setTimeout(cancel,500);
        })
        .on('keydown',function(ev){
          // capturamos el evento enter para aplicar los cambios
          if (ev.key === 'Enter' || ev.keyCode === 13) {
            apply(ev);
          }
        });
    });


    // los campos que guardan la data
    var $userAddress = $('#branch_address_attributes_original_address');
    var $detail = $('#branch_address_attributes_detail_address');
    var $normalAddress = $('#branch_address_attributes_normal_address');
    var $status = $('#branch_address_attributes_processing_status');
    var $latitude = $('#branch_address_attributes_latitude');
    var $longitude = $('#branch_address_attributes_longitude');
    var $placeId = $('#branch_address_attributes_google_place_id');
    var $city = $('#city_name');
    var $state = $('#state_name');
    var $country = $('#country_name');
    var $changed_address = $('#changed_address');

    // el widget de busqueda
    var $visibleAddress = $('#map_address');

    // el contenedor donde ira el mapa
    var $mapContainer = $('#map_container');

    $visibleAddress.on('change', function(){
      $normalAddress.val('');
      $status.val('');
      $latitude.val('');
      $longitude.val('');
      $placeId.val('');
      $city.val('');
      $state.val('');
      $country.val('');
      $changed_address.val(true);
    })
    const providerCountry = $mapContainer.data("country");
    const getCoordinatesByCountry = () => {
      switch (providerCountry) {
        case "CL":
          return {lat: -33.4386278, lng: -70.6731206, is_default: true};
        case "MX":
          return {lat: 19.431106, lng: -99.134619, is_default: true};
        case "CO":
          return {lat: 4.708490, lng: -74.073037, is_default: true};
        case "EC":
          return {lat: -0.1665775, lng: -78.4312596, is_default: true};
        default:
          return {lat: -33.4386278, lng: -70.6731206, is_default: true};
      }
    }

    // funcion para obtener el centro actual o inicial del mapa
    var initialCenter = function(){
      var lat = $latitude.val();
      var lon = $longitude.val();
      // ahora "normalizamos" la posicion inicial
      if ((+lat + "" === lat) && (+lon + "" === lon)) {
        // ok, tenemos coordenadas iniciales
        return { lat: +lat, lng: +lon };
      }
      // centro por defecto
      // var defaultLatLng = {lat: -33.4209175, lng: -70.6045151};
      // return { lat: -33.4378037, lng: -70.652802, is_default: true };
      return getCoordinatesByCountry();
    };

    if($('.include-google-maps').length){
      /**
       * Port parcial de la gema Geocoder (Ruby) y como lee los resultados en Geocoder::Result::Google
       * @param placeResult [google.maps.places.PlaceResult]
       */
      var GeocoderGoogleResult = function(placeResult){

        this.data = placeResult;

        var _addressComponents = placeResult.address_components;

        var _firstAddressComponentsOfType = function(type){
          return _addressComponents.find(function(c){ return c.types.includes(type); });
        };

        this.address = function(){
          return placeResult.formatted_address;
        };
        this.formattedAddress = this.address;

        this.latitude = function() {
          return placeResult.geometry.location.lat();
        };
        this.longitude = function() {
          return placeResult.geometry.location.lng();
        };

        this.city = function(){
          var fields = [
            'locality',
            'sublocality',
            'administrative_area_level_3',
            'administrative_area_level_2'
          ];
          var i, f, entity;
          for (i = 0; i < fields.length; i++) {
            f = fields[i];
            entity = _firstAddressComponentsOfType(f);
            if (entity) {
              return entity.long_name;
            }
          }
          return null; // no appropriate components found
        };

        this.state = function(){
          var entity = _firstAddressComponentsOfType('administrative_area_level_1');
          if (entity) {
            return entity.long_name;
          }
          return null;
        };

        this.country = function(){
          var entity = _firstAddressComponentsOfType('country');
          if (entity) {
            return entity.long_name;
          }
          return null;
        };

        this.place_id = function(){
          return placeResult.place_id;
        };

      };

      /**
       * @param result [GeocoderGoogleResult]
       */
      var readPlace = function(result){

        // actualizamos la direccion y todos los valores
        $normalAddress.val( result.formattedAddress() );

        $latitude.val( result.latitude() );
        $longitude.val( result.longitude() );
        $placeId.val( result.place_id() );
        $city.val( result.city() );
        $state.val( result.state() );
        $country.val( result.country() );

        $status.val( $status.data('clientSuccessStatus') );

        // Log the event to Google Analytics
        gtag('event', 'place_selected', {
          'event_category': 'Maps',
          'event_label': result.formattedAddress(),
          'gerty_business_name': $mapContainer.data('providerBusinessName'),
          'gerty_provider_id': $mapContainer.data('providerId')
        });
      };

      // lo que si o si haremos sera que cuando cambia el campo de texto visible, actualiza la direccion que se supone escribe el usuario
      $visibleAddress.on('change',function() {
        $userAddress.val($visibleAddress.val());
      });

      let mapLoaded = function(){

        var center = initialCenter();

        var map = new google.maps.Map($mapContainer[0], {
          center: center,
          draggable: false,
          fullscreenControl: false,
          mapTypeControl: false,
          streetViewControl: false,
          zoom: center.is_default ? 9 : 17
        });

        // creamos la marca en el mapa en el centro actual
        var marker = new google.maps.Marker({ map: map});
        // y si esta en una posicion real, la marcamos
        if (!(center.is_default)) {
          marker.setPosition(center);
          marker.setVisible(true);
        }

        // Log the map drag event to Google Analytics
        map.addListener('dragend', function() {
          gtag('event', 'map_drag', {
            'event_category': 'Maps',
            'event_label': 'Map dragged',
            'gerty_business_name': $mapContainer.data('providerBusinessName'),
            'gerty_provider_id': $mapContainer.data('providerId')
          });
        });

        // Log the map zoom event to Google Analytics
        map.addListener('zoom_changed', function() {
          gtag('event', 'map_zoom', {
            'event_category': 'Maps',
            'event_label': 'Map zoomed',
            'zoom_level': map.getZoom(),
            'gerty_business_name': $mapContainer.data('providerBusinessName'),
            'gerty_provider_id': $mapContainer.data('providerId')
          });
        });

        // y el buscador de direcciones
        var visibleInput = $visibleAddress[0];

        var autocomplete = new google.maps.places.Autocomplete(visibleInput);
        autocomplete.bindTo('bounds', map);
        //map.controls[google.maps.ControlPosition.TOP_LEFT].push(visibleInput);
        //map.controls[google.maps.ControlPosition.TOP_LEFT].push(document.getElementById('detail-container'));

        autocomplete.addListener('place_changed', function() {
          // siempre, si o si actualizamos la direccion "del usuario"
          $userAddress.val($visibleAddress.val());
          var place = autocomplete.getPlace();
          if (!place.geometry) {
            // ignoramos el resultado
            return;
          }

          // movemos el mapa
          if (place.geometry.viewport) {
            map.fitBounds(place.geometry.viewport);
          } else {
            map.setCenter(place.geometry.location);
            map.setZoom(17);
          }

          // ponemos el marcador
          marker.setPlace({
            placeId: place.place_id,
            location: place.geometry.location
          });
          marker.setVisible(true);

          readPlace( new GeocoderGoogleResult(place) );

          // Log the event to Google Analytics
          gtag('event', 'place_changed', {
            'event_category': 'Maps',
            'event_label': place.formatted_address,
            'gerty_business_name': $mapContainer.data('providerBusinessName'),
            'gerty_provider_id': $mapContainer.data('providerId')
          });
        });

      };

      let key = $('#container_branch_form').data('gkey');

      let script = document.createElement('script');
      script.src = 'https://maps.googleapis.com/maps/api/js?key='+key+'&libraries=places&callback=initMap';
      script.defer = true;

      // Attach your callback function to the `window` object
      window.initMap = mapLoaded;

      // Append the 'script' element to 'head'
      document.head.appendChild(script);
    } // end if google maps

  }

  /////////////////////////////
  // banco en wizard
  /////////////////////////////

  let clearModal = function () {
    let modal = $('#bank_account_modal'),
      inputs = modal.find('input.form-control'),
      select = modal.find('select');
    inputs.val('');
    select.val(select.find('option:first').val());
  }

  let showMessages = function () {
    $('.bank-account-messages').removeClass('d-none');
  }

  let hideMessages = function () {
    $('.bank-account-messages').addClass('d-none');
  }

  let checkSelected = function () {
    let select = $('#branch_bank_account_id');
    if (select.val() === "") {
      showMessages();
    }else {
      hideMessages();
    }
  }

  $('#bank_account_modal').find('.close-modal').on('click', function (e) {
    clearModal();
  });

  $(".dropdown-item.add-bank-account").on('click', function(e){
    $(this).addClass('clicked');
    e.preventDefault();
  });

  $("#create_bank_account_form").on("ajax:success", function(event) {
    
    let detail = event.detail[0],
      success = detail.success;
    if(success){
      let content = detail.content,
        showInfo = detail.info,
        bankAccount = detail.bank_account,
        dropdown = $('#bank-account-dropdown-menu'),
        dropdownMenuBtn = $('#dropdownMenuButton-bank'),
        bankAccountClicked = $(".dropdown-item.add-bank-account.clicked"),
        modal = $('#bank_account_modal'),
        closeBtn = modal.find('.close'),
        bankAccountInput = $('#branch_bank_account_id'); // XXX branch_bank_account_id
      const selectBankAccount = $("#billing_bank_account_id");
      if(selectBankAccount){
        selectBankAccount.append(
          `<option value="${bankAccount.id}" >${showInfo}</option>`
        )
      }
      dropdown.append(content);
      dropdownMenuBtn.html(showInfo);
      bankAccountClicked.removeClass('clicked');
      bankAccountInput.val(bankAccount.id);
      checkSelected();
      closeBtn.click();
    }else{
      event.preventDefault();
    }

  }).on("ajax:error", function(event) {
    event.preventDefault();
  });

  $("#create_dm_provider_form").on("ajax:success", function(event) {
    let detail = event.detail[0],
    success = detail.success;
    if(success){
      let content = detail.content,
        showInfo = detail.info,
        bankAccount = detail.bank_account,
        dropdown = $('#dm-provider-dropdown-menu'),
        dropdownMenuBtn = $('#dropdownMenuButton-dm-provider'),
        bankAccountClicked = $(".dropdown-item.add-dm-provider.clicked"),
        modal = $('#dm-provider_modal'),
        closeBtn = modal.find('.close');
      dropdown.append(content);
      dropdownMenuBtn.html(showInfo);
      bankAccountClicked.removeClass('clicked');
      checkSelected();
      closeBtn.click();
    }else{
      event.preventDefault();
    }
  }).on("ajax:error", function(event) {
    event.preventDefault();
  });

  $('#container_branch_form').on('click','.dropdown-item.bank-account-created', function(e){
    let _this = $(this),
      bankAccountInput = $('#branch_bank_account_id'),
      dropdownMenuBtn = $('#dropdownMenuButton-bank'),
      id = _this.data('bank-account-id'),
      info = _this.data('bank-account-info');
    bankAccountInput.val(id);
    dropdownMenuBtn.html(info);
    checkSelected();
    e.preventDefault();
  });

  $('#container_branch_form').on('click', '.dropdown-item.add-bank-account', function(e){
    $(this).addClass('clicked');
    e.preventDefault();
  });


});