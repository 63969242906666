import { MarkerClusterer } from "@googlemaps/markerclusterer";

$(document).on('turbolinks:load', function() {
  if ($('#provider_maps_index').length) {
    let mapLoaded = function(){
      // el widget de busqueda
      let $mapInput = $('.map-search-field');
      let visibleInput = $mapInput.find('input').get(0);
      let $poweredGerty = $('#powered_by_gerty');

      // el contenedor donde ira el mapa
      let $mapContainer = $('#map_container');
      let markerIconUrl = $mapContainer.data('markerIconUrl');
      let data = $mapContainer.data('mapPoints');
      const providerCountry = $mapContainer.data("country");
      const withClusters = $mapContainer.data("withClusters");

      const getCoordinatesByCountry = () => {
        switch (providerCountry) {
          case "CL":
            return {lat: -33.4386278, lng: -70.6731206, is_default: true};
          case "MX":
            return {lat: 19.431106, lng: -99.134619, is_default: true};
          case "CO":
            return {lat: 4.708490, lng: -74.073037, is_default: true};
          case "EC":
            return {lat: -0.1665775, lng: -78.4312596, is_default: true};
          default:
            return {lat: -33.4386278, lng: -70.6731206, is_default: true};
        }
      }

      // funcion para obtener el centro actual o inicial del mapa
      let initialCenter = function(){
        // centro por defecto
        // var defaultLatLng = {lat: -33.4209175, lng: -70.6045151};
        if (data.length > 0 && data.length < 20) {
          return {lat: data[0].latitude, lng: data[0].longitude, is_default: true}
        } else {
          return getCoordinatesByCountry();
        }
      };

      let center = initialCenter();

      let mapOptions = {
        center: center,
        draggable: true,
        fullscreenControl: false,
        mapTypeControl: false,
        streetViewControl: false,
        // zoomControlOptions: {
        //   position: google.maps.ControlPosition.RIGHT_TOP
        // },
        zoom: data.length < 10 ? 12 : 5
      }

      let styleOptions = [
        {
          "elementType": "geometry",
          "stylers": [
            {
              "color": "#f5f5f5"
            }
          ]
        },
        {
          "elementType": "labels.icon",
          "stylers": [
            {
              "visibility": "off"
            }
          ]
        },
        {
          "elementType": "labels.text.fill",
          "stylers": [
            {
              "color": "#616161"
            }
          ]
        },
        {
          "elementType": "labels.text.stroke",
          "stylers": [
            {
              "color": "#f5f5f5"
            }
          ]
        },
        {
          "featureType": "administrative.land_parcel",
          "elementType": "labels.text.fill",
          "stylers": [
            {
              "color": "#bdbdbd"
            }
          ]
        },
        {
          "featureType": "landscape.natural",
          "elementType": "labels.text.fill",
          "stylers": [
            {
              "color": "#267f37"
            }
          ]
        },
        {
          "featureType": "landscape.natural",
          "elementType": "labels.text.stroke",
          "stylers": [
            {
              "color": "#ffffff"
            }
          ]
        },
        {
          "featureType": "poi",
          "stylers": [
            {
              "visibility": "off"
            }
          ]
        },
        {
          "featureType": "poi",
          "elementType": "geometry",
          "stylers": [
            {
              "color": "#eeeeee"
            }
          ]
        },
        {
          "featureType": "poi",
          "elementType": "labels.text.fill",
          "stylers": [
            {
              "color": "#757575"
            }
          ]
        },
        {
          "featureType": "poi.attraction",
          "stylers": [
            {
              "visibility": "on"
            }
          ]
        },
        {
          "featureType": "poi.government",
          "stylers": [
            {
              "visibility": "on"
            }
          ]
        },
        {
          "featureType": "poi.park",
          "stylers": [
            {
              "visibility": "on"
            }
          ]
        },
        {
          "featureType": "poi.park",
          "elementType": "geometry",
          "stylers": [
            {
              "color": "#e5e5e5"
            }
          ]
        },
        {
          "featureType": "poi.park",
          "elementType": "geometry.fill",
          "stylers": [
            {
              "visibility": "on"
            }
          ]
        },
        {
          "featureType": "poi.park",
          "elementType": "geometry.stroke",
          "stylers": [
            {
              "visibility": "simplified"
            }
          ]
        },
        {
          "featureType": "poi.park",
          "elementType": "labels.text.fill",
          "stylers": [
            {
              "color": "#9e9e9e"
            }
          ]
        },
        {
          "featureType": "road",
          "elementType": "geometry",
          "stylers": [
            {
              "color": "#ffffff"
            }
          ]
        },
        {
          "featureType": "road.arterial",
          "elementType": "geometry.fill",
          "stylers": [
            {
              "color": "#ffffff"
            }
          ]
        },
        {
          "featureType": "road.arterial",
          "elementType": "geometry.stroke",
          "stylers": [
            {
              "color": "#e3e3e7"
            }
          ]
        },
        {
          "featureType": "road.arterial",
          "elementType": "labels.text.fill",
          "stylers": [
            {
              "color": "#757575"
            }
          ]
        },
        {
          "featureType": "road.highway",
          "elementType": "geometry",
          "stylers": [
            {
              "color": "#dadada"
            }
          ]
        },
        {
          "featureType": "road.highway",
          "elementType": "geometry.fill",
          "stylers": [
            {
              "color": "#fdeba1"
            }
          ]
        },
        {
          "featureType": "road.highway",
          "elementType": "geometry.stroke",
          "stylers": [
            {
              "color": "#f8d26a"
            }
          ]
        },
        {
          "featureType": "road.highway",
          "elementType": "labels.text.fill",
          "stylers": [
            {
              "color": "#616161"
            }
          ]
        },
        {
          "featureType": "road.local",
          "elementType": "labels.text.fill",
          "stylers": [
            {
              "color": "#9e9e9e"
            }
          ]
        },
        {
          "featureType": "transit",
          "stylers": [
            {
              "visibility": "on"
            }
          ]
        },
        {
          "featureType": "transit.line",
          "elementType": "geometry",
          "stylers": [
            {
              "color": "#e5e5e5"
            }
          ]
        },
        {
          "featureType": "transit.station",
          "stylers": [
            {
              "weight": 1
            }
          ]
        },
        {
          "featureType": "transit.station",
          "elementType": "geometry",
          "stylers": [
            {
              "color": "#eeeeee"
            }
          ]
        },
        {
          "featureType": "water",
          "elementType": "geometry",
          "stylers": [
            {
              "color": "#c9c9c9"
            }
          ]
        },
        {
          "featureType": "water",
          "elementType": "geometry.fill",
          "stylers": [
            {
              "color": "#aadaff"
            }
          ]
        },
        {
          "featureType": "water",
          "elementType": "labels.text.fill",
          "stylers": [
            {
              "color": "#9e9e9e"
            }
          ]
        }
      ];

      let map = new google.maps.Map($mapContainer[0], mapOptions),
        mapStyles = new google.maps.StyledMapType(styleOptions, {name: "Edited"}),
        currentInfoWindow = null;

      map.mapTypes.set("Edited", mapStyles);
      map.setMapTypeId('Edited');
      console.log($mapContainer.data('financierEmail'), $mapContainer.data('userId'))

      // Log the event to Google Analytics
      map.addListener('dragend', function() {
        gtag('event', 'map_drag', {
          'event_category': 'Maps',
          'event_label': 'Map dragged',
          'gerty_financier_name': $mapContainer.data('financierEmail').split('@')[0],
          'gerty_user_id': $mapContainer.data('userId'),
          'value': 1
        });
      });
      // Log the event to Google Analytics
      map.addListener('zoom_changed', function() {
        gtag('event', 'map_zoom', {
          'event_category': 'Maps',
          'event_label': 'Map zoomed',
          'gerty_financier_name': $mapContainer.data('financierEmail').split('@')[0],
          'gerty_user_id': $mapContainer.data('userId'),
          'value': map.getZoom()
        });
      });

      let loadPoints = function(data){
        let created = []
        const labels = "ABCDEFGHIJKLMNOPQRSTUVWXYZ";
        data.forEach(function (provider, index) {
          let currentElement = created.find(el => el["latitude"] === provider["latitude"] && el["longitude"] === provider["longitude"] && provider["latitude"] !== 0 && provider["longitude"] !== 0)
          let newElement = {latitude: provider["latitude"], longitude: provider["longitude"], provider: provider}
          if (currentElement) {
            let earth = 6378.137
            let pi = Math.PI
            let m = (1 / ((2 * pi / 360) * earth)) / 1000;
            var new_latitude = provider["latitude"] + (20 * m);
            var new_longitude = provider["longitude"] + (20 * m);
            provider["latitude"] = new_latitude
            provider["longitude"] = new_longitude
          } else {
            created.push(newElement)
          }
        })

        const markers = created.map((item, index) => {
          const provider = item.provider;
          const label = labels[index % labels.length];

          let marker = new google.maps.Marker({
            map: map,
            position: {
              lat: item["latitude"],
              lng: item["longitude"]
            },
            // label,
            title: item["business_name"],
            visible: true,
            icon: {url: markerIconUrl}
          })

          let phonelink_html = function (phone){
            let cleanphone = phone.replace(/[^0-9+]/g,'');
            return `<a href="tel:${cleanphone}">${phone}</a>`;
          }
          let link_html = function(url,text = null) {
            if (!text) {
              text = url
            }
            if (!url.match(/^https?:\/\//i)) {
              url = 'http://' + url;
            }
            return `<a href="${url}" target="_blank">${text}</a>`;
          }
          let info_html = function(content, icon=false) {
            let icon_html = icon ? `<i class="${icon}"></i>` : '';
            let info_extra_class = icon ? '' : "info-window__info--continue";
            let html  =
              `<p class="info-window__info ${info_extra_class}">` +
              '<span class="info-window__icon">' +
              icon_html +
              '</span> '+
              content +
              '</p>';
            return html;
          }

          let head_content = `<div class='info-window__head'>`+
            `<h5 class="info-window__title">${provider["business_name"]}</h5>`+
            `<p class="info-window__addresss">${provider["address1"]}</p>`+
            `<p class="info-window__addresss">${provider["address2"]}</p>`+
            `</div>`;
          let body_content = `<div class='info-window__body'>`;
          if(provider["phones"]){
            provider["phones"].forEach(function(phone){
              body_content += info_html(phonelink_html(phone),'fa fa-phone fa-flip-horizontal');
            });
          }
          else{ // si no existen los de address usará los de map_info
            if(provider["phone1"]){
              body_content += info_html(phonelink_html(provider["phone1"]),'fa fa-phone fa-flip-horizontal');
            }
            if(provider["phone2"]){
              body_content += info_html(phonelink_html(provider["phone2"]),'fa fa-phone fa-flip-horizontal');
            }
          }
          if(provider["page_url"]){
            body_content += info_html(link_html(provider["page_url"]),'fa fa-globe');
          }
          if(provider["schedule_url"]){
            body_content += info_html(link_html(provider["schedule_url"], "Agenda tu hora en línea"),'fa fa-calendar-alt');
          }
          let asterisk_used = false;
          if (provider["benefit1"]) {
            body_content += info_html(provider["benefit1"], 'fa fa-asterisk');
            asterisk_used = true;
          }
          if (provider["benefit2"]) {
            body_content += info_html(provider["benefit2"], asterisk_used ? null : 'fa fa-asterisk');
            asterisk_used = true;
          }
          if (provider["benefit3"]) {
            body_content += info_html(provider["benefit3"], asterisk_used ? null : 'fa fa-asterisk');
          }
          body_content += `</div>`

          let contentString = `<div class='info-window'>`+
            head_content +
            body_content +
            `</div>`;

          let infoWindow = new google.maps.InfoWindow({
            content: contentString
          });

          marker.addListener('click', function() {
            if (currentInfoWindow != null && currentInfoWindow !== infoWindow) {
              currentInfoWindow.close();
            }
            infoWindow.open(map, marker);
            currentInfoWindow = infoWindow;
            map.setCenter(marker.getPosition());
            map.setZoom(17);

            // Log the event to Google Analytics
            gtag('event', 'marker_click', {
              'event_category': 'Maps',
              'event_label': 'Marker Click',
              'gerty_financier_name': $mapContainer.data('financierEmail').split('@')[0],
              'gerty_user_id': $mapContainer.data('userId'),
              'value': 1
            });
          });
          return marker;

        });

        if (withClusters) {

          const renderer = {
            render: ({count, position}) => {
              return new google.maps.Marker({
                label: { text: String(count), color: "white", fontSize: "10px" },
                title: `${count} clínicas en este lugar`,
                icon: {
                  url: `data:image/svg+xml;base64,${count > 15 ? svg("#ff0000") : svg()}`,
                  scaledSize: new google.maps.Size(40, 40),
                },
                position,
                // adjust zIndex to be above other markers
                zIndex: Number(google.maps.Marker.MAX_ZINDEX) + count,
              })
            }
          }

          new MarkerClusterer({
            markers, map, renderer
          });
        }
      }

      loadPoints(data);

      // y el buscador de direcciones
      let poweredGerty = $poweredGerty[0];

      let autocomplete = new google.maps.places.Autocomplete(visibleInput);
      autocomplete.bindTo('bounds', map);
      map.controls[google.maps.ControlPosition.TOP_LEFT].push($mapInput[0]);
      map.controls[google.maps.ControlPosition.BOTTOM_CENTER].push(poweredGerty);
      //map.controls[google.maps.ControlPosition.TOP_LEFT].push(document.getElementById('detail-container'));

      autocomplete.addListener('place_changed', function() {
        let place = autocomplete.getPlace();
        if (!place.geometry) {
          // ignoramos el resultado
          return;
        }

        // movemos el mapa
        if (place.geometry.viewport) {
          map.fitBounds(place.geometry.viewport);
        } else {
          map.setCenter(place.geometry.location);
          map.setZoom(15);
        }
        // Log the event to Google Analytics
        gtag('event', 'place_selected', {
          'event_category': 'Maps',
          'event_label': place.formatted_address,
          'gerty_financier_name': $mapContainer.data('financierEmail').split('@')[0],
          'gerty_user_id': $mapContainer.data('userId'),
          'value': 1
        });
      });

    };

    let key = $('#map_container').data('gkey');

    let script = document.createElement('script');
    script.src = 'https://maps.googleapis.com/maps/api/js?key='+key+'&libraries=places&callback=initMap';
    script.defer = true;

    // Attach your callback function to the `window` object
    window.initMap = mapLoaded;

    // Append the 'script' element to 'head'
    document.head.appendChild(script);
  }
})


const svg = (color = "#4236ff") => {
  return window.btoa(`
                <svg fill="${color}" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 240 240">
                <circle cx="120" cy="120" opacity="1" r="70" />
                <circle cx="120" cy="120" opacity=".7" r="90" />
                <circle cx="120" cy="120" opacity=".3" r="110" />
                <circle cx="120" cy="120" opacity=".2" r="130" />
                </svg>`);
}
