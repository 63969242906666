import $ from "jquery";

document.addEventListener("turbolinks:load", () => {
  if ($('#caja_los_andes_details_credito_emergencia').length) {
    $('#calculated_installment_amount').text(get_installment_value($('#dropdown-installments').val()))
    $('#total_solicitado').text(numberToCurrencyCLP(total_lending_amount))
    $('#dropdown-installments').change(function () {
      let selected_value = $(this).val()
      $('#calculated_installment_amount').text(get_installment_value(selected_value))
    })

    function get_installment_value(selected_value) {
      let aux = Math.floor(requested_amount / selected_value)
      aux = Math.min(aux, max_installment_amount)
      return numberToCurrencyCLP(aux)
    }

    function numberToCurrencyCLP(number) {
      // este regex encuentra digigos seguidos de grupos de a 3 digitos, y tras el grupo capturado inserta "." ('$1.')
      return '$ ' + number.toFixed(0).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.');
    }

  }
})
